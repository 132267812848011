import React from 'react'

export const Loader = () => {
    return (
        <div className="container-fluid bg_spans text-center">
            <div className="col-lg-2 offset-lg-5 text-center fs-1 text-white">
                Loading
            </div>
        </div>
    )
}
