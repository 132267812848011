import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.webp';
import logos from '../assets/img/logos.webp';

export default class header extends Component {
    render() {
        return (
            <div className='header'>
                <div className="row g-0">
                    <div className="col-xl-3 d-flex justify-content-center align-items-center py-2">
                        <Link to="/home">
                            <img className="img-fluid" src={logo} alt="logo"></img>
                        </Link>
                    </div>
                    <div className="col-lg-4 d-flex justify-content-center align-items-center p-2 p-md-0">
                        <img className='img-fluid' src={logos} alt="logos of sports"></img>
                    </div>
                    <div className="d-none col-5 d-lg-block">
                        <div className="d-flex p-2 justify-content-center align-items-center px-lg-2">
                            <form action="https://wager.mvpglobalsports.com/Qubic/SecurityPage.php" method="post" name="client login" >
                                <div className="row p-2 text-white textShadow">
                                    <div className="col-4 text-center">
                                        <input name="customerID" type="text" class="form-control" placeholder="USER" aria-label="USER" autoFocus />
                                    </div>
                                    <div className="col-4 text-center">
                                        <input type="password" name="Password" placeholder="Password" class="form-control" />
                                        <input type="hidden" name="action" value="1" />
                                    </div>
                                    <div className="col-4 text-center">
                                        <button type="submit" className="btn btn_login">Login</button>
                                        <input type="hidden" name="action" value="1" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="d-block d-lg-none">
                    <div className="d-flex pt-3 justify-content-center align-items-center px-lg-2">
                        <form action="https://wager.mvpglobalsports.com/mQubic/SecurityPage.php" method="post" name="client login" >
                            <div className="row p-2 text-white textShadow">
                                <div className="col-4 text-center">
                                    <input name="customerID" type="text" class="form-control" placeholder="USER" aria-label="USER" autoFocus />
                                </div>
                                <div className="col-4 text-center">
                                    <input type="password" name="Password" placeholder="Password" class="form-control"/>
                                     <input type="hidden" name="action" value="1"/>
                                </div>
                                <div className="col-4 text-center">
                                    <button type="submit" className="btn btn_login">Login</button>
                                    <input type="hidden" name="action" value="1"/>
                                </div>
                            </div>
                        </form>
                    </div>

                    </div>


                </div>
            </div>
        )
    }
}
